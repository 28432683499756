<template>
  <div class="expande-horizontal centraliza wrap">
    <v-dialog persistent width="350" v-model="modal_add_client">
      <v-card style="border-radius: 21px;" width="400" class="pa-3">
        <div class="expande-horizontal wrap">
          <v-flex xs12>
            <LottieAnimation
              class="pa-6 pb-0 pt-0"
              ref="new_client"
              :animationData="require('@/apps/shared/assets/new_client.json')"
            />
          </v-flex>
          <div class="expande-horizontal mb-3 column">
            <h3 class="fonte blue--text">Bem vindo(a)</h3>
            <span class="fonte grey--text">
              Precisamos de algumas informações
            </span>
          </div>
          <v-flex class="pt-3" xs12>
            <v-form ref="formClient">
              <v-flex xs12>
                <span class="fonte blue--text fonteMini font-weight-bold">
                  Nome:
                </span>
                <v-text-field
                  solo
                  flat
                  background-color="#f2f2f2"
                  v-model="first_contact_name"
                  placeholder="ex: Patrick"
                  :rules="[v => !!v || 'Preencha aqui']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <span class="blue--text fonte fonteMini font-weight-bold">
                  Telefone ou Whatsapp:
                </span>
                <v-text-field
                  solo
                  flat
                  background-color="#f2f2f2"
                  v-model="first_contact_phone_or_whatsapp"
                  placeholder="ex: 96 984196827"
                  v-mask="['## #########']"
                  :rules="[v => !!v || 'Preencha aqui']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-btn
                  rounded
                  color="blue"
                  block
                  @click="saveFirstContact"
                  class="fonte font-weight-bold"
                  dark
                  >Preenchido</v-btn
                >
              </v-flex>
            </v-form>
          </v-flex>
        </div>
      </v-card>
    </v-dialog>
    <v-flex xs12 md8>
      <v-img
        style="border-radius: 3px;"
        height="180"
        :src="get_marketplace.loja ? get_marketplace.loja.coverImage : ''"
      >
        <div
          class="expande-horizontal px-6 column pt-4"
          style="border-radius: 6px; width: 100%; height: 180px; background: rgba(0,0,0,0.4); flex-align: flex-end;"
        >
          <v-spacer></v-spacer>
          <div class="expande-horizontal">
            <v-flex xs3></v-flex>
            <v-flex xs9>
              <div class="expande-horizontal wrap">
                <v-spacer></v-spacer>
                <v-chip
                  :color="$theme.primary"
                  v-if="
                    get_marketplace.configuracao &&
                      get_marketplace.configuracao.delivery_is_active
                  "
                  class="fonte ma-1 font-weight-bold white--text"
                  x-small
                >
                  estamos abertos
                </v-chip>
                <v-chip
                  v-if="
                    get_marketplace.configuracao &&
                      get_marketplace.configuracao.delivery_is_active
                  "
                  :color="$theme.primary"
                  class="fonte ma-1 font-weight-bold white--text"
                  x-small
                >
                  fazemos entrega
                </v-chip>
                <v-chip
                  v-if="
                    get_marketplace.loja &&
                      get_marketplace.loja.local.local_pickup
                  "
                  :color="$theme.primary"
                  class="fonte ma-1 font-weight-bold white--text"
                  x-small
                >
                  pode vir buscar
                </v-chip>
                <v-chip
                  v-if="
                    get_marketplace.loja &&
                      get_marketplace.loja.local.delivery_time
                  "
                  :color="$theme.primary"
                  class="fonte ma-1 font-weight-bold white--text"
                  x-small
                >
                  <span class="ml-1">{{
                    get_marketplace.loja.local.delivery_time
                  }}</span>
                </v-chip>
              </div>
            </v-flex>
            <!-- <span class="fonte fonte-micro" style="padding-top: 5px;">5.0</span> -->
            <!-- <v-rating
              class="ml-3"
              length="5"
              size="12"
              :value="5"
              :color="$theme.secondary"
            ></v-rating> -->
            <!-- <span class="fonte fonte-micro ml-3" style="padding-top: 5px;"
              >10 avaliações</span
            > -->
          </div>
          <!-- <h3 class="fonte white--text">{{ get_marketplace.loja.nome }}</h3> -->
        </div>
      </v-img>
      <div
        class="expande-horizontal pl-2"
        style="position: relative; top: -50px"
      >
        <img
          class="mr-6"
          style="height: 80px; width: 80px; border-radius: 50%;"
          v-if="get_marketplace.loja && get_marketplace.loja.profileImage"
          :src="get_marketplace.loja.profileImage"
        />
        <!-- <div class="expande-horizontal column">
          <div style="height: 50px;"></div>
        </div> -->
      </div>
    </v-flex>
    <v-flex style="position: relative; top: -50px;" class="mx-0" xs12 md8>
      <div class="expande-horizontal wrap">
        <v-flex xs12 class="pt-2">
          <v-list class="pa-0 ma-0">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  v-if="get_marketplace.loja"
                  class="fonte font-weight-bold"
                >
                  {{ get_marketplace.loja.nome }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <!-- <span class="fonte fonte-micro\" style="width: 100px;">
                    {{ categoria_escolhida.nome }}
                  </span> -->
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-flex>
        <v-flex class="px-1 pt-0 pb-2" xs12>
          <v-slide-group show-arrows>
            <v-slide-item
              v-for="categoria in get_marketplace.produtos"
              v-show="
                categoria.preco_multiplo &&
                  categoria.preco_multiplo.filter(preco => preco.disponivel)
                    .length
              "
              :key="categoria._id"
            >
              <v-btn
                class="mx-2 mb-1 fonte elevation-0 text-capitalize fonte-bold"
                :input-value="categoria._id === categoria_escolhida._id"
                active-class="active-cat elevation-0"
                @click.prevent="setCategory(categoria)"
              >
                <span
                  class="fonte font-weight-bold elevation-0"
                  :class="{
                    'white--text': categoria._id === categoria_escolhida._id,
                    'red--text': categoria._id !== categoria_escolhida._id
                  }"
                >
                  {{ categoria.nome }}
                </span>
              </v-btn>
            </v-slide-item>
          </v-slide-group>
        </v-flex>
        <v-flex xs12>
          <div class="expande-horizontal wrap">
            <v-flex xs12 class="pa-3 pt-0">
              <!-- <span
                v-if="inputSearch === ''"
                class="fonte fonte-bold grey--text mt-3 mb-3"
                >Produtos</span
              > -->
              <span
                v-if="inputSearch !== ''"
                class="fonte fonte-bold grey--text mt-3 mb-3"
                >Produtos Encontrados -
                {{ productSearchList.length }} encontrados</span
              >
            </v-flex>
            <v-flex xs12>
              <v-list dense v-show="inputSearch === ''">
                <template v-for="produto in categoria_escolhida.preco_multiplo">
                  <v-list-item
                    v-show="produto.disponivel"
                    class="fonte list-item-store"
                    :key="produto.nome"
                    @click="openModalAddProduct(produto)"
                  >
                    <v-avatar class="mr-3" size="60" rounded>
                      <v-img v-if="produto.image" :src="produto.image" />
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        class="fonte font-weight-bold fonte-micro"
                      >
                        {{ produto.nome }}
                      </v-list-item-title>
                      <span class="mt-1 mb-1 fonte-micro grey--text">{{ produto.descricao }}</span>
                    </v-list-item-content>
                    <v-list-item-action class="font-weight-bold green--text">
                      {{ $helper.formataSaldo(produto.preco_com_markup) }}
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list>
            </v-flex>
            <v-flex
              class="pa-3"
              :key="produto.nome"
              v-show="inputSearch !== ''"
              v-for="produto in productSearchList"
              xs3
            >
              <v-card
                @click.prevent="openModalAddProduct(produto)"
                color="#F0F0F0"
                min-height="70"
                class="mx-auto"
                max-width="344"
                flat
              >
                <v-img
                  height="60"
                  v-if="produto.image"
                  :src="produto.image"
                ></v-img>

                <div
                  class="expande-horizontal centraliza"
                  style="min-height: 60px;"
                >
                  <h6 class="text-center fonte fonte-bold">
                    {{ produto.nome }}
                  </h6>
                </div>
                <h4 class="text-center fonte fonte-bold" style="color: #DF693E">
                  {{ $helper.formataSaldo(produto.preco_com_markup) }}
                </h4>

                <div class="expande-horizontal centraliza">
                  <v-btn
                    @click.prevent="openModalAddProduct(produto)"
                    icon
                    small
                  >
                    <v-icon size="18" color="#17B51C">
                      mdi-plus-circle-outline
                    </v-icon>
                  </v-btn>
                </div>
              </v-card>
            </v-flex>
          </div>
        </v-flex>
      </div>
    </v-flex>
    <ModalAddProduct
      :addProductToCart="addProductToCart"
      :removeProductOfCart="removeProductOfCart"
      ref="modalAddProduct"
    />
    <div
      style="position: fixed; bottom: 0; left: 0; right: 0; z-index: 100;"
      class="expande-horizontal centraliza"
    >
      <v-flex class="px-0" xs12 md8>
        <v-card
          :color="$theme.primary"
          class="fonte  expande-horizontal elevation-0"
        >
          <v-expansion-panels
            class="elevation-0"
            flat
            v-if="
              get_marketplace.loja &&
                get_marketplace.loja.configuracao &&
                get_marketplace.loja.configuracao.delivery_is_active
            "
            style="padding: 0px; background: red;"
            :color="$theme.primary"
          >
            <v-expansion-panel>
              <v-expansion-panel-header
                style="border-bottom: 1px dashed #F44336;border-top: 1px dashed #F44336;"
                class="fonte"
                expand-icon="mdi-menu-down"
                ref="expansionPanelHeader"
              >
                <v-list color="transparent" class="pa-0 ma-0" dense>
                  <v-list-item v-if="saleStage === 0" class="pa-0 ma-0">
                    <v-avatar class="pa-0 ma-0 mr-2" color="red">
                      <v-icon color="#f2f2f2">mdi-basket-outline</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        style="color: red;"
                        class="font-weight-bold"
                      >
                        {{ get_compra.produtos.length }} produtos, total de
                        {{ $helper.formataSaldo(get_compra.preco_total || 0) }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="fonte-micro">
                        Clique para visualizar os produtos
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="saleStage === 1" class="pa-0 ma-0">
                    <v-avatar class="pa-0 ma-0 mr-2" color="red">
                      <v-icon color="#f2f2f2">mdi-map-marker-outline</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        style="color: red;"
                        class="font-weight-bold"
                      >
                        Endereço de entrega
                      </v-list-item-title>
                      <v-list-item-subtitle class="fonte-micro">
                        Escolha ou cadastre um abaixo
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="saleStage === 2" class="pa-0 ma-0">
                    <v-avatar class="pa-0 ma-0 mr-2" color="red">
                      <v-icon size="36" color="#f2f2f2">mdi-cash</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        style="color: red;"
                        class="font-weight-bold"
                      >
                        FORMA DE PAGAMENTO
                      </v-list-item-title>
                      <v-list-item-subtitle class="fonte-micro">
                        Por favor, informe como deseja pagar
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="saleStage === 3" class="pa-0 ma-0">
                    <v-avatar class="pa-0 ma-0 mr-2" color="red">
                      <v-icon size="36" color="#f2f2f2"
                        >mdi-information-variant</v-icon
                      >
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        style="color: red;"
                        class="font-weight-bold"
                      >
                        CONFIRME OS DETALHES
                      </v-list-item-title>
                      <v-list-item-subtitle class="fonte-micro">
                        confira seu pedido e finalize
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex class="pt-2" xs12>
                  <v-window :touchless="true" v-model="saleStage">
                    <v-window-item :key="0">
                      <div
                        v-if="get_compra.produtos.length"
                        style="height: 62vh;"
                        class="expande-horizontal pa-3 pr-0 pl-0 column"
                      >
                        <v-list
                          style="max-height: 800px; overflow: auto;"
                          class="pa-0 ma-0 pt-1 pb-1 fonte"
                          dense
                        >
                          <template v-for="(produto, i) in get_compra.produtos">
                            <v-list-item
                              style="background: #f2f2f2; border: 1px solid #e0e0e0; border-radius: 6px;"
                              @click="openModalAddProduct(produto, `${i}`)"
                              class="pl-3 pr-3 ma-0 mb-3"
                              :key="`product-added-${i}`"
                            >
                              <v-list-item-content>
                                <v-list-item-title class="font-weight-bold">
                                  {{ produto.quantity }} {{ produto.nome }}
                                </v-list-item-title>
                                <template v-for="items in produto.adicionais">
                                  <v-list-item-subtitle
                                    v-show="item.quantidade_adicionada"
                                    class="ml-1 mt-1"
                                    :key="`${i}-${_i}-adicional`"
                                    v-for="(item, _i) in items.itens"
                                  >
                                    {{ item.quantidade_adicionada }}
                                    {{ item.descricao_personalizada }}
                                    <!-- <span class="green--text"> + {{ item.preco }} </span> -->
                                  </v-list-item-subtitle>
                                </template>
                                <v-list-item-subtitle
                                  v-if="produto.obs"
                                  class="ml-1 mb-1 fonte-micro"
                                >
                                  <span class="font-weight-bold">Obs:</span>
                                  {{ produto.obs }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  class="fonte-micro green--text ml-1"
                                >
                                  {{
                                    $helper.formataSaldo(
                                      produto.seller_price || 0
                                    )
                                  }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-avatar size="70" rounded>
                                  <img
                                    class="img-icon"
                                    v-if="produto.image"
                                    :src="produto.image"
                                  />
                                </v-avatar>
                              </v-list-item-action>
                            </v-list-item>
                          </template>
                        </v-list>
                        <v-spacer></v-spacer>
                        <div class="pt-3 expande-horizontal column">
                          <h3 class="fonteMini pa-3 red--text">
                            Escolha a forma de entrega:
                          </h3>
                          <v-btn
                            rounded
                            v-if="get_compra.produtos.length > 0"
                            class="text-capitalize elevation-0"
                            @click="saleStage = 1"
                            color="red"
                            dark
                            block
                          >
                            Entrega em casa
                            <v-icon class="ml-3">mdi-map-marker-outline</v-icon>
                          </v-btn>
                          <v-btn
                            rounded
                            v-if="
                              get_compra.produtos.length > 0 &&
                                get_marketplace.loja.local &&
                                get_marketplace.loja.local.local_pickup
                            "
                            class="text-capitalize mt-2 elevation-0"
                            @click="vouBuscar"
                            color="red"
                            dark
                            block
                          >
                            Vou buscar
                            <v-icon class="ml-10">mdi-walk</v-icon>
                          </v-btn>
                        </div>
                      </div>
                      <div
                        v-else
                        style="height: 62vh;"
                        class="expande-horizontal centraliza pa-3 pr-0 pl-0 column"
                      >
                        <v-flex xs10 md4>
                          <LottieAnimation
                            class="pa-6"
                            ref="anim2"
                            :animationData="
                              require('@/apps/shared/assets/empty_cart.json')
                            "
                          />
                        </v-flex>
                        <v-flex xs10 md10>
                          <div class="expande-horizontal fonte grey--text">
                            Sua cesta está vazia
                          </div>
                        </v-flex>
                        <v-flex xs10 md10>
                          <div
                            class="expande-horizontal centraliza fonte grey--text"
                          >
                            <v-btn
                              @click="closeExpansionPanelCheckout"
                              class="font-weight-bold"
                              :color="$theme.primary"
                              rounded
                              dark
                              >Adicionar itens
                              <v-icon class="ml-2"
                                >mdi-plus-circle-outline</v-icon
                              >
                            </v-btn>
                          </div>
                        </v-flex>
                      </div>
                    </v-window-item>
                    <v-window-item :key="1">
                      <div class="expande-horizontal pa-0 ma-0">
                        <ChangeDelivery
                          :ok="
                            (address, areaToTakeTax) =>
                              setAddress(address, areaToTakeTax)
                          "
                          :cancel="() => (saleStage = 0)"
                          :stepBack="() => (saleStage = 0)"
                        />
                      </div>
                    </v-window-item>
                    <v-window-item :key="2">
                      <div
                        class="fonte expande-horizontal column"
                        style="height: 61vh;"
                      >
                        <v-list dense class="pa-0 ma-0 fonte">
                          <v-list-item
                            @click="
                              selectPaymentForm(
                                get_marketplace.loja.payment_menurocket_config[
                                  'money'
                                ],
                                'money'
                              )
                            "
                            v-if="
                              get_marketplace.loja.payment_menurocket_config[
                                'money'
                              ]
                            "
                            class="pa-0 mt-3 item-payment-list"
                          >
                            <v-avatar>
                              <v-icon :color="$theme.secondary">
                                mdi-cash
                              </v-icon>
                            </v-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="font-weight-bold">
                                Dinheiro
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Pague com dinheiro
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item
                            @click="
                              selectPaymentForm(
                                get_marketplace.loja.payment_menurocket_config[
                                  'debit'
                                ],
                                'debit'
                              )
                            "
                            v-if="
                              get_marketplace.loja.payment_menurocket_config[
                                'debit'
                              ]
                            "
                            class="pa-0 mt-3 item-payment-list"
                          >
                            <v-avatar>
                              <v-icon :color="$theme.secondary">
                                mdi-credit-card-chip-outline
                              </v-icon>
                            </v-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="font-weight-bold">
                                Débito
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Pague no cartão de débito
                              </v-list-item-subtitle>
                              <v-list-item-subtitle></v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item
                            @click="
                              selectPaymentForm(
                                get_marketplace.loja.payment_menurocket_config[
                                  'credit'
                                ],
                                'credit'
                              )
                            "
                            v-if="
                              get_marketplace.loja.payment_menurocket_config[
                                'credit'
                              ]
                            "
                            class="pa-0 mt-3 item-payment-list"
                          >
                            <v-avatar>
                              <v-icon :color="$theme.secondary">
                                mdi-credit-card-outline
                              </v-icon>
                            </v-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="font-weight-bold">
                                Crédito
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Pague no cartão de crédito
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                        <!-- <v-btn @click="saleStage = 3"></v-btn> -->
                      </div>
                    </v-window-item>
                    <v-window-item :key="3">
                      <div
                        class="fonte expande-horizontal column"
                        style="min-height: 61vh;"
                      >
                        <v-list three-line class="pa-0 ma-0" dense>
                          <v-list-item @click="saleStage = 0" class="mb-1 pl-0">
                            <v-avatar class="mr-3 mb-10" color="white">
                              <v-icon :color="$theme.primary"
                                >mdi-basket-outline</v-icon
                              >
                            </v-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="fonte font-weight-bold"
                                >Revisar</v-list-item-title
                              >
                              <v-list-item-subtitle>
                                {{ get_compra.produtos.length }} Produtos:
                                {{
                                  $helper.formataSaldo(
                                    get_compra.preco_total || 0
                                  )
                                }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle v-if="get_compra.address"
                                ><span class="fonte"
                                  >Entrega:
                                  {{
                                    $helper.formataSaldo(
                                      get_compra.address.tax_value || 0
                                    )
                                  }}</span
                                ></v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item
                            @click="saleStage = 1"
                            v-if="get_compra.address"
                            class="mb-1 pl-0"
                          >
                            <v-avatar class="mr-3 mb-10" color="white">
                              <v-icon :color="$theme.primary"
                                >mdi-map-marker-outline</v-icon
                              >
                            </v-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="fonte white--text font-weight-bold"
                                >Endereço</v-list-item-title
                              >
                              <v-list-item-subtitle>{{
                                get_compra.address.formatted_address
                              }}</v-list-item-subtitle>
                              <v-list-item-subtitle
                                ><span class="white--text"
                                  >.</span
                                ></v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item
                            v-if="get_compra.address"
                            class="mb-1 pl-0"
                          >
                            <v-avatar class="mr-3 mb-10" color="white">
                              <v-icon :color="$theme.primary"
                                >mdi-moped-outline</v-icon
                              >
                            </v-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="fonte white--text font-weight-bold"
                                >Taxa de entrega</v-list-item-title
                              >
                              <v-list-item-subtitle>
                                R$
                                {{
                                  get_compra.address.tax_value
                                }}</v-list-item-subtitle
                              >
                              <v-list-item-subtitle>
                                {{ get_compra.address.tax_time_delivery }}
                                minutos</v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item @click="saleStage = 2" class="mb-1 pl-0">
                            <v-avatar class="mr-3 mb-10" color="white">
                              <v-icon :color="$theme.primary"
                                >mdi-clock-outline</v-icon
                              >
                            </v-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="fonte white--text font-weight-bold"
                                >Forma de pagamento</v-list-item-title
                              >
                              <v-list-item-subtitle
                                v-if="get_compra.payment_form"
                              >
                                {{
                                  get_compra.payment_form.nome
                                }}</v-list-item-subtitle
                              >
                              <v-list-item-subtitle
                                ><span class="white--text"
                                  >.</span
                                ></v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item class="mb-1 pl-0">
                            <v-avatar class="mr-3 mb-10" color="white">
                              <v-icon :color="$theme.primary">mdi-cash</v-icon>
                            </v-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="fonte font-weight-bold"
                                >Total da compra</v-list-item-title
                              >
                              <v-list-item-subtitle
                                ><span class="grey--text font-weight-bold">{{
                                  $helper.formataSaldo(totalWithAddressTax())
                                }}</span></v-list-item-subtitle
                              >
                              <v-list-item-subtitle
                                ><span class="white--text"
                                  >.</span
                                ></v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>

                          <v-spacer></v-spacer>
                          <!-- <v-list-item class="">
                            <v-btn
                              small
                              text
                              :color="$theme.primary"
                              dark
                              @click="saleStage = 0"
                              >
                                <v-icon size="18" class="mr-3">mdi-chevron-left</v-icon>
                              Revisar pedido</v-btn
                            >
                          </v-list-item> -->
                        </v-list>
                        <v-btn
                          :color="$theme.primary"
                          dark
                          rounded
                          class="fonte elevation-0 font-weight-bold"
                          @click="realizarPedido"
                          >Realizar pedido</v-btn
                        >
                      </div>
                    </v-window-item>
                  </v-window>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-flex>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalAddProduct from "../components/ModalAddProduct.vue";
import ChangeDelivery from "../components/changeDeliveryStage.vue";
import LottieAnimation from "lottie-web-vue";

export default {
  props: ["id"],
  data() {
    return {
      categoria_escolhida: {
        preco_multiplo: []
      },
      first_contact_name: "",
      first_contact_phone_or_whatsapp: "",
      productSearchList: [],
      inputSearch: "",
      saleStage: 0,
      is_changed: false,
      localAddress: [],
      formNewAddress: false,
      to_update: "",
      modal_add_client: false
    };
  },
  components: {
    ModalAddProduct,
    ChangeDelivery,
    LottieAnimation
  },
  computed: {
    ...mapGetters(["get_marketplace", "get_compra"])
  },
  watch: {
    get_marketplace: function(val) {
      this.setFirstCategory(val);
    }
  },
  methods: {
    vouBuscar() {
      this.get_compra.tipo_de_entrega = "retirada";
      this.get_compra.tipo = "retirada";
      this.saleStage = 2;
    },
    entregaEmCasa() {
      this.get_compra.tipo = "delivery";
      this.get_compra.tipo_de_entrega = "delivery";
      this.saleStage = 1;
    },
    totalWithAddressTax() {
      const total_sell = this.get_compra.preco_total;
      let total_tax = 0;
      if (this.get_compra.address) {
        total_tax = parseFloat(this.get_compra.address.tax_value);
      }
      const total = total_sell + total_tax;
      return total;
    },
    closeExpansionPanelCheckout() {
      this.$refs.expansionPanelHeader.$emit("click", true);
    },
    ...mapActions([
      "show_marketplace",
      "createGlobalMessage",
      "calcula_valor_total_da_compra"
    ]),
    setAddress(address, areaTax) {
      this.get_compra.address = {
        ...address,
        tax_value: parseInt(areaTax.value),
        tax_description: areaTax.description,
        tax_time_delivery: areaTax.time_delivery
      };
      this.saleStage = 2;
      this.to_update = "2";
    },
    setFirstCategory(val) {
      if (val.produtos.length) {
        this.changed = true;
        this.categoria_escolhida = val.produtos[0];
      }
    },
    setCategory(categoria) {
      this.categoria_escolhida = categoria;
    },
    openModalAddProduct(produto, withIndex) {
      if (
        this.get_marketplace.loja &&
        this.get_marketplace.loja.configuracao &&
        this.get_marketplace.loja.configuracao.delivery_is_active
      ) {
        if (produto.is_done) {
          this.createGlobalMessage({
            type: "info",
            message:
              "Este produto já foi marcado como feito no gestor, não pode ser editado ou removido!",
            timeout: 6000
          });
        } else {
          let prod = produto;
          if (withIndex === 0 || withIndex) {
            prod.indexProd = parseInt(withIndex);
          }
          this.$refs.modalAddProduct.open(prod);
        }
      } else {
        this.createGlobalMessage({
          type: "info",
          message: "Opa, esta loja está fechada no momento!",
          timeout: 6000
        });
      }
    },
    addProductToCart(product) {
      if (product.indexProd === 0 || product.indexProd > 0) {
        this.get_compra.produtos[product.indexProd] = product;
      } else {
        this.get_compra.produtos.push(product);
      }
      this.$refs.modalAddProduct.close();
      this.createGlobalMessage({
        type: "success",
        message: "Produto adicionado com sucesso!",
        timeout: 6000
      });
      this.calcula_valor_total_da_compra();
    },
    removeProductOfCart(product) {
      if (product.indexProd === 0 || product.indexProd > 0) {
        let new_products = [];
        this.get_compra.produtos.map((p, i) => {
          if (i !== product.indexProd) {
            new_products.push(p);
          }
        });
        this.get_compra.produtos = new_products;
        this.createGlobalMessage({
          type: "success",
          message: "Produto removido com sucesso!",
          timeout: 6000
        });
        this.$forceUpdate();
      }
      this.$refs.modalAddProduct.close();
      this.calcula_valor_total_da_compra();
    },
    selectPaymentForm(paymentForm, name) {
      this.get_compra.payment_form = {
        ...paymentForm,
        nome: this.getPaymentFormName(name),
        name: name
      };
      this.saleStage = 3;
    },
    getPaymentFormName(name) {
      if (name === "debit") {
        return "Débito";
      }
      if (name === "debit") {
        return "Crédito";
      }
      if (name === "money") {
        return "Dinheiro";
      }
      if (name === "pix") {
        return "Pix";
      }
    },
    realizarPedido() {
      const self = this;
      this.$store.dispatch("enableLoading");
      self
        .$run(
          "transacoes/fazer-pedido",
          {
            compra: self.get_compra,
            marketplace: self.get_marketplace,
            caixa: {
              tipo_caixa: "menurocket",
              funcionario: {
                nome: "menurocket"
              }
            }
          },
          "client"
        )
        .then(res => {
          self.$store.dispatch("disableLoading");
          if (localStorage.last_buy) {
            localStorage.removeItem("last_buy");
          }
          self.$router.push(`/acompanhar/${res.data._id}`);
        })
        .catch(e => {
          self.$store.dispatch("disableLoading");
          console.log(e);
        });
    },
    loadAddresses() {
      if (localStorage.menu_rocket_addresses) {
        this.localAddress = JSON.parse(localStorage.menu_rocket_addresses);
      }
    },
    saveFirstContact() {
      if (this.$refs.formClient.validate()) {
        this.get_compra.cliente = {
          nome: this.first_contact_name,
          telefone: this.first_contact_phone_or_whatsapp
        };
        localStorage.first_contact_menu_rocket = "yes";
        localStorage.first_contact_name = this.first_contact_name;
        localStorage.first_contact_phone_or_whatsapp = this.first_contact_phone_or_whatsapp;
        this.createGlobalMessage({
          type: "success",
          message:
            `Bem vindo(a), ${this.first_contact_name}.`,
          timeout: 6000
        });
        this.modal_add_client = false;
      }
    }
  },
  mounted() {
    this.loadAddresses();
  },
  created() {
    this.show_marketplace(this.id);
    if (localStorage.first_contact_menu_rocket === "yes") {
      this.get_compra.cliente = {
        nome: localStorage.first_contact_name,
        telefone: localStorage.first_contact_phone_or_whatsapp
      };
    } else {
      this.modal_add_client = true;
    }
  }
};
</script>

<style>
.img-icon {
  width: 78px;
  height: 78px;
  margin-right: 12px;
  border-radius: 6px;
  border: none;
}
.list-item-store {
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px dashed #d8d8d8;
  font-weight: 500;
  line-height: 17px;
  color: #363636;
}
.list-item-font {
  color: #363636;
}
.active-cat {
  background-color: #f44336 !important;
  background: #f44336 !important;
  color: #f44336 !important;
}
.item-payment-list {
  color: #f44336 !important;
  margin-top: 21px !important;
}
</style>
