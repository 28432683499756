<template lang="pug">  
div.expande-horizontal.wrap.pt-0
  v-flex(xs12 v-if="addresses.length > 0 && !formNewAddress" style="height: 57vh")
    div.expande-horizontal.wrap
      div.expande-horizontal
        h4.red--text.fonte.pt-1.pb-0 Escolha o endereço:
      v-flex(xs12)
        v-list.pa-0.ma-0.fonte(three-line style="max-height: 35vh; overflow: auto;")
          v-list-item.item-address-list(v-for="(address, index) in addresses" @click="saveAddress(false, index)" :key="`to-select-item-address-${index}`")
            v-list-item-content
              v-list-item-title 
                span.font-weight-bold Endereço 
              v-list-item-subtitle 
                span {{ address.formatted_address }}
              v-list-item-subtitle 
                v-btn(dark x-small color="red") selecionar
        v-list-item.mt-3(@click="initFormNewAddress")
          v-avatar.mr-3(color="red")
            v-icon(color="white") mdi-map-marker-plus-outline
          v-list-item-content
            v-list-item-title 
              span.orante--text.font-weight-bold Outro endereço
            v-list-item-subtitle 
              span.orante--text.font-weight-bold cadastre um novo endereço aqui

  v-flex(xs12 v-if="formNewAddress" style="height: 57vh")
    div.expande-horizontal.column
      v-flex(xs12)
        div.expande-horizontal
          v-text-field(
            label="Escreva seu endereço com número"
            v-model="search"
            autofocus
            placeholder="ex: Rua Dr Braulino, n1990"
            color="red"
            append-icon="mdi-magnify"
            @keyup.enter.prevent="searchAddress"
            @click:append="searchAddress"
          )
          v-btn(@click="cancelFormAddress" color="red" rounded class="white--text mt-3 ml-3" icon) 
            v-icon mdi-close-circle-outline 
      div.expande-horizontal(style="height: 48vh")
        MglMap(
          :interactive="true" :zoom="zoom" :showZoom="true" :center="center" style="background-color: #555; border-radius: 6px;" :accessToken="accessToken" :mapStyle.sync="mapStyle"
        )
          MglMarker(
            v-for="(address, index) in addresses"
            :key="`address-map-market-${index}`"
            :coordinates="[address.geometry.location.lng, address.geometry.location.lat]" :color="$theme.primary"
          )
            MglPopup(
              showed :coordinates="[address.geometry.location.lng, address.geometry.location.lat]" anchor="top"
            )
              div.pa-0.ma-0.centraliza
                div
                  span.fonte.text-center.mt-3 Você está aqui?
                v-btn(@click="saveAddress(true, index)" dark small rounded block :color="$theme.primary") estou aqui
  v-flex(xs12)
    div.expande-horizontal
      v-btn(@click="stepBack" text color="red" dark)
        v-icon.mr-2(size="18") mdi-chevron-left
        span.fonte.font-weight-bold voltar
  //- v-flex(xs12 v-if="false").pt-3
  //-   div.card-method-form.expande-horizontal.wrap.fonte
  //-     v-flex(xs12)
  //-       v-form(ref="payer_form")
  //-         div.expande-horizontal
  //-           v-flex(xs7)
  //-             v-text-field(
  //-               dense
  //-               label="CEP",
  //-               v-model="address.zip_code"
  //-               type="number"
  //-               @input="searchCep"
  //-               :rules="cepRules"
  //-               v-mask="['########']"
  //-               placeholder="########"
  //-             )
  //-           v-flex.pl-2(xs4)
  //-             v-text-field(
  //-               dense
  //-               v-model="address.number"
  //-               label="Nº",
  //-               :rules="[v => !!v || 'Preencha aqui']",
  //-               ref="addressNumber"
  //-             ) 
  //-         div.expande-horizontal
  //-           v-flex(xs12)
  //-             v-text-field(
  //-               dense
  //-               v-model="address.street"
  //-               label="Endereço",
  //-               :rules="[v => !!v || 'Preencha aqui']",
  //-               outlined
  //-             ) 
  //-         v-flex(xs12)
  //-           div.expande-horizontal
  //-             v-flex.mr-3(xs6)
  //-               v-text-field(
  //-                 v-model="address.city"
  //-                 dense,
  //-                 label="Cidade",
  //-                 placeholder="ex: Macapá"
  //-                 outlined
  //-               ) 
  //-             v-flex(xs6)
  //-               v-text-field(
  //-                 v-model="address.bairro"
  //-                 dense,
  //-                 label="Bairro",
  //-                 :rules="[v => !!v || 'Preencha aqui']"
  //-                 placeholder="ex: Meio do Mundo"
  //-                 outlined
  //-               ) 
  //-     v-flex.pb-3(xs12)
  //-       div.expande-horizontal
  //-         v-btn(dark,text,@click="cancel"
  //-         ,color="#3EB4DF" rounded small)
  //-           v-icon mdi-chevron-left
  //-           span voltar
  //-         v-spacer         
  //-         v-btn.fonte.ml-3(dark,@click="saveAddress",color="#3EB4DF" rounded small)       
  //-           span avançar
  //-           v-icon mdi-chevron-right

</template>

<script>
import axios from "axios";
import Mapbox from "mapbox-gl";
import { MglMap, MglMarker, MglPopup } from "vue-mapbox";
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["ok", "cancel", "stepBack"],
  components: { MglMap, MglMarker, MglPopup },
  data() {
    return {
      cepRules: [v => !!v || "preencha seu cep"],
      address: {
        zip_code: ""
      },
      accessToken:
        "pk.eyJ1IjoicHRrbG9ycmFuIiwiYSI6ImNrM2JzbXRqZTBnZjkzbnFlM3VyYTVzazkifQ.kgDrdfqGfd6XTV5DXfeSwg", // your access token. Needed if you using Mapbox maps
      mapStyle: "mapbox://styles/mapbox/light-v10",
      mapbox: Mapbox,
      coordinates: [],
      locations: [],
      zoom: 18,
      center: [-51.153764, 0.00427],
      options: [],
      search: "",
      geocoder: new google.maps.Geocoder(),
      addresses: [],
      formNewAddress: false
    };
  },
  computed: {
    ...mapGetters(["get_marketplace"])
  },
  methods: {
    ...mapActions(["createGlobalMessage"]),
    async searchCep() {
      if (this.address.zip_code) {
        if (this.address.zip_code.length === 8) {
          const res = await axios.get(
            `https://viacep.com.br/ws/${this.address.zip_code}/json/`
          );
          if (res.status === 200) {
            this.address.street = res.data.logradouro;
            this.address.district = res.data.bairro;
            this.address.city = res.data.localidade;
            this.address.state = res.data.uf;
            this.address.bairro = res.data.bairro;
            this.address.range_numbers = res.data.complemento;
            this.address.number = "";
            this.$refs.addressNumber.focus();
            this.$forceUpdate();
          }
        }
      }
    },
    verifyArea(point, polygon) {
      function pointInPolygon(p, polygon) {
        const point = [p["lng"], p["lat"]];
        var x = point[0],
          y = point[1];
        var inside = false;
        for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
          var xi = polygon[i][0],
            yi = polygon[i][1];
          var xj = polygon[j][0],
            yj = polygon[j][1];
          var intersect =
            yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
          if (intersect) inside = !inside;
        }
        return inside;
      }
      var isInside = pointInPolygon(point, polygon);
      return isInside;
    },
    saveAddress(isNew, index) {
      const storeDeliveryAreas = this.get_marketplace.loja.range_delivery_tax;
      let areaToDelivery = false;
      let addressHasFinded = false;
      // if (this.$refs.payer_form.validate()) {
      if (isNew) {
        const hasPreviousAddress = localStorage.menu_rocket_addesses
          ? true
          : false;
        let previousAddresses = [];
        if (hasPreviousAddress) {
          previousAddresses = JSON.parse(localStorage.menu_rocket_addesses);
          localStorage.menu_rocket_addesses = JSON.stringify([
            this.addresses[0],
            ...previousAddresses
          ]);
          storeDeliveryAreas.map(areaToVerify => {
            if (!areaToDelivery) {
              if (
                this.verifyArea(
                  this.addresses[index].geometry.location,
                  areaToVerify.area[0].geometry.coordinates[0]
                )
              ) {
                addressHasFinded = true;
                this.ok(this.addresses[index], areaToVerify);
              }
            }
            return;
          });
        } else {
          localStorage.menu_rocket_addesses = JSON.stringify([
            this.addresses[0]
          ]);
          storeDeliveryAreas.map(areaToVerify => {
            if (!areaToDelivery) {
              if (
                this.verifyArea(
                  this.addresses[0].geometry.location,
                  areaToVerify.area[0].geometry.coordinates[0]
                )
              ) {
                addressHasFinded = true;
                this.ok(this.addresses[0], areaToVerify);
              }
            }
            return;
          });
          return;
        }
      } else {
        storeDeliveryAreas.map(areaToVerify => {
          if (!areaToDelivery) {
            if (
              this.verifyArea(
                this.addresses[index].geometry.location,
                areaToVerify.area[0].geometry.coordinates[0]
              )
            ) {
              addressHasFinded = true;
              this.ok(this.addresses[index], areaToVerify);
            }
          }
          return;
        });
      }
      if (!addressHasFinded) {
        this.createGlobalMessage({
          type: "error",
          timeout: 6000,
          message: "Poxa, a loja não realiza entrega no endereço escolhido."
        });
      }
      this.formNewAddress = false;
      setTimeout(() => {
        this.loadAddresses();
      }, 2000);
      // }
    },
    haversine(lat1, lon1, lat2, lon2) {
      const R = 6371; // raio médio da Terra em km
      const dLat = ((lat2 - lat1) * Math.PI) / 180; // diferença de latitude em radianos
      const dLon = ((lon2 - lon1) * Math.PI) / 180; // diferença de longitude em radianos
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos((lat1 * Math.PI) / 180) *
          Math.cos((lat2 * Math.PI) / 180) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c; // distância em km
      return distance;
    },
    cancelFormNewAddress() {
      this.formNewAddress = false;
    },
    initFormNewAddress() {
      this.formNewAddress = true;
    },
    async searchAddress() {
      if (!this.search.trim()) {
        this.addresses = [];
        return;
      }

      try {
        this.loading = true;

        const response = await axios.get(
          "https://maps.googleapis.com/maps/api/geocode/json",
          {
            params: {
              address: this.search,
              key: "AIzaSyAa5Gv7Du2ezz8fw6SHHY82vyDl7vIKO8U"
            }
          }
        );

        response.data.results.map(res => {
          this.center = [res.geometry.location.lng, res.geometry.location.lat];
          this.zoom = 16;
        });

        this.addresses = response.data.results;
        // this.geocoder
        //   .geocode({ address: this.search })
        //   .then(result => {
        //     console.log('res', result);
        //     const { results } = result;
        //     this.addresses = result;

        //     // map.setCenter(results[0].geometry.location);
        //     // marker.setPosition(results[0].geometry.location);
        //     // marker.setMap(map);
        //     // responseDiv.style.display = "block";
        //     // response.innerText = JSON.stringify(result, null, 2);
        //     return results;
        //   })
        //   .catch(e => {
        //     alert("Geocode was not successful for the following reason: " + e);
        //   });
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    loadAddresses() {
      const hasAddresses = localStorage.menu_rocket_addesses ? true : false;
      const addresses = localStorage.menu_rocket_addesses;
      if (hasAddresses) {
      this.addresses = JSON.parse(addresses);
      } else {
       this.initFormNewAddress();
      }
      // this.initFormNewAddress();
    },
    cancelFormAddress() {
      this.formNewAddress = false;
      this.loadAddresses();
    }
  },
  created() {
    this.loadAddresses();
  }
};
</script>

<style scoped>
.card-method-card {
  max-width: 300px;
}
.card-title {
  color: #363636;
}
.card-container {
  width: 100%;
  display: flex;
  background: #f0f0f0;
  border: 1px solid #0da8f0;
  border-radius: 10px;
}
.item-address-list {
  margin-top: 12px;
  background: rgba(235, 0, 0, 0.1);
  border-radius: 6px;
}
.mapboxgl-ctrl-logo {
  display: none !important;
}
.mapboxgl-ctrl-bottom-right {
  display: none !important;
}
.mapboxgl-ctrl {
  display: none;
}
.mapboxgl-ctrl-attrib {
  display: none;
}
.mapboxgl-ctrl-bottom-right {
  position: relative;
  bottom: -200px;
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important;
}
</style>
